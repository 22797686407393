import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  getActivePunchOverrides,
  getWorkforceUpcomingSchedule,
} from 'utils/network/apis';
import { Badge, BadgeVariant } from '@hubportal/components';
import { Employee } from 'models/employee';
import { ProfileItem } from './profileItem';
import { Actions } from './actions';
import classnames from 'classnames';
import usePermissions, { Permission } from 'utils/hooks/usePermissions';

interface PunchOverride {
  ec_id: string;
  created_at: string;
  expired_at: string;
}

interface Schedule {
  start_at: string;
  hub: string;
}

const Profile = ({
  employee,
  onUpdate,
}: {
  employee: Employee | null;
  onUpdate: () => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { isAllowed } = usePermissions();

  const [activePunchInOverride, setActivePunchInOverride] =
    useState<PunchOverride>();

  const [upcomingSchedule, setUpcomingSchedule] = useState<Schedule>();

  const getActiveOverride = async (): Promise<void> => {
    try {
      const response = await getActivePunchOverrides(employee?.ecID);
      if (response.results && response.results?.length > 0) {
        setActivePunchInOverride(response.results[0]);
      }
    } catch (err) {}
  };

  const getUpcomingSchedule = async (): Promise<void> => {
    try {
      const response = await getWorkforceUpcomingSchedule(
        employee?.workforceID
      );
      if (response?.start_at) {
        setUpcomingSchedule({
          start_at: response?.start_at,
          hub: response?.hub_slug,
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (employee?.ecID) {
      getActiveOverride();
      getUpcomingSchedule();
    }
  }, [employee?.ecID]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <div className="header-m text-white">{employee?.getFullName()}</div>
          {(isAllowed(Permission.READ_COMPLIANCE_ALL) ||
            isAllowed(Permission.READ_COMPLIANCE_ALLOWED) ||
            isAllowed(Permission.READ_COMPLIANCE_LIMITED) ||
            isAllowed(Permission.READ_PERFORMANCE_LIMITED)) &&
            employee?.probationEndDate &&
            !employee?.isProbationPassed() && (
              <Badge variant={BadgeVariant.orange}>
                {t('probation_ends', {
                  date: format(
                    new Date(employee?.probationEndDate || 0),
                    'dd.MM.yyyy'
                  ),
                })}
              </Badge>
            )}
          {employee?.isBlocked && (
            <Badge variant={BadgeVariant.red}>{t('blocked')}</Badge>
          )}
          {employee?.isShortTerm && (
            <Badge variant={BadgeVariant['dark-gray']}>{t('external')}</Badge>
          )}
        </div>
        <Actions
          employee={employee}
          activePunchInOverride={activePunchInOverride}
          getActivePunchInOverride={getActiveOverride}
          onUpdate={onUpdate}
        />
      </div>
      <div className="flex gap-8">
        <ProfileItem
          copyable
          label={t('employee_number')}
          value={employee?.ecID}
        />
        <ProfileItem
          copyable
          label={t('riderId')}
          value={employee?.workforceID}
        />
      </div>
      <div className="flex gap-20 py-4">
        <ProfileItem
          direction="col"
          label={t('status')}
          value={
            employee?.status ? (
              <div className="flex items-baseline">
                <span
                  className={classnames(
                    'w-2 h-2 rounded mr-2 shrink-0',
                    employee?.getStateColor()
                  )}
                />
                {employee?.getState()}
              </div>
            ) : (
              '-'
            )
          }
        />
        <ProfileItem
          direction="col"
          label={t('job_position')}
          value={employee?.jobTitle}
        />
        <ProfileItem
          direction="col"
          label={t('contract_started')}
          value={
            employee?.hireDate
              ? format(new Date(employee.hireDate), 'dd.MM.yyyy')
              : '-'
          }
        />
        {employee?.ecID && (
          <ProfileItem
            direction="col"
            label={`${t('next_shift')} ${
              upcomingSchedule?.hub ? `(${upcomingSchedule?.hub})` : ''
            }`}
            value={
              upcomingSchedule?.start_at
                ? format(new Date(upcomingSchedule?.start_at), 'EEE dd.MM.yyyy')
                : '-'
            }
          />
        )}
        {employee?.ecID &&
          employee?.vacationBalanceLeft !== undefined &&
          employee?.vacationBalanceLeft !== null &&
          employee?.vacationBalanceLeft >= 0 && (
            <ProfileItem
              direction="col"
              label={t('vacation_balance')}
              value={t('vacation_balance_left', {
                days: employee?.vacationBalanceLeft || 0,
              })}
            />
          )}
      </div>
      <div>
        {employee?.hubSlug?.startsWith('nl') && employee.isUnderage() && (
          <ProfileItem
            label={`${t('age_restriction')}`}
            value={t('rider_underage_warning')}
          />
        )}
      </div>
      {activePunchInOverride && (
        <ProfileItem
          label=""
          value={t('create_punch_in_override_active', {
            time: format(new Date(activePunchInOverride.expired_at), 'HH:mm'),
          })}
        />
      )}
    </div>
  );
};

export { Profile };
